<template>
  <div class="settings-payments">
    <div v-if="isAnyCard" class="settings-payments__cards-cont">
      <card
        v-for="card in cards"
        :key="card.id"
        :data="card"
        class="settings-payments__card"
        @cardaction="handleAction"
      />
    </div>
    <v-btn color="primary" class="settings-payments__add-btn" @click="setFormState(true, $event)">
      {{ $t("Add payment method") }}
    </v-btn>
    <card-form v-if="formOpened" />
    <errors-snackbar :show.sync="showWarnings" :errors="warnings"> </errors-snackbar>
    <info-snackbar :show.sync="showInfo" :message="infoMessage"> </info-snackbar>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import PaymentCard from "./SettingsPaymentCard";
import CardForm from "./SettingsPaymentAddCardModal";
import { setPrimaryCard, removeCard } from "@/api";
import ErrorsSnackbar from "@/components/ErrorsSnackbar.vue";
import InfoSnackbar from "@/components/InfoSnackbar.vue";

export default {
  components: {
    card: PaymentCard,
    "card-form": CardForm,
    ErrorsSnackbar,
    InfoSnackbar
  },
  data: () => ({
    showWarnings: false,
    showInfo: false,
    infoMessage: "",
    warnings: []
  }),
  computed: {
    ...mapState("settingsPaymentsStore", ["formOpened", "cards"]),
    isAnyCard() {
      return this.cards && this.cards.length;
    }
  },
  methods: {
    ...mapMutations("settingsPaymentsStore", ["setFormState"]),
    ...mapActions("settingsPaymentsStore", ["getAllPaymentCards"]),
    handleAction(params) {
      if (params.act === "delete") {
        removeCard(params.id)
          .then(r => {
            this.getAllPaymentCards();
            if (Object.hasOwnProperty.call(r.data, "error")) {
              this.warnings = [r.data.error];
              this.showWarnings = true;
            }
          })
          .catch(err => {
            this.warnings =
              err.response.status !== 500
                ? err.response.data.errors
                : [this.$t("Something went wrong, please try again later")];
            this.showWarnings = true;
          });
      } else if (params.act === "primary") {
        setPrimaryCard(params.id).then(() => {
          this.getAllPaymentCards();
        });
      }
    }
  },
  mounted() {
    this.getAllPaymentCards();
  }
};
</script>

<style scoped>
.settings-payments {
  display: block;
  width: 100%;
}
.settings-payments__add-btn {
  display: inline-block;
  padding: 0 35px;
  font-size: 24px;
  line-height: 78px;
  color: #ffffff;
  background-color: #005fce;
  border-radius: 12px;
}
.settings-payments__cards-cont {
  display: flex;
  flex-wrap: wrap;
}
.settings-payments__card {
  width: 100%;
  max-width: 335px;
  margin-bottom: 25px;
}
.settings-payments__card:not(:last-of-type) {
  margin-right: 20px;
}
@media (max-width: 767px) {
  .settings-payments__cards-cont {
    flex-direction: column;
    align-items: center;
  }
}
</style>
