<template>
  <v-dialog v-model="formOpened" persistent max-width="600px">
    <v-card>
      <v-card-title>
        <span class="headline">{{ $t("Add card") }}</span>
      </v-card-title>
      <v-container>
        <v-form>
          <div id="card-element" class="add-card-form__input-cont"></div>
          <div id="card-errors" class="add-card-form__card-errors" ref="cardErrors"></div>
          <div class="add-card-form__btns-wrapper">
            <button class="add-card-form__btn" type="button" @click="setFormState(false, $event)">
              {{ $t("Cancel") }}
            </button>
            <button
              class="add-card-form__btn"
              :disabled="isBlocked"
              @click="stripeTokenHandler($event)"
            >
              {{ $t("Add") }}
            </button>
          </div>
        </v-form>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import { checkCurrentLanguage } from "@/api";

export default {
  data() {
    return {
      isBlocked: false,
      stripe: "",
      elements: "",
      card: ""
    };
  },
  computed: {
    ...mapState("settingsPaymentsStore", ["formOpened"])
  },
  methods: {
    ...mapMutations("settingsPaymentsStore", ["setFormState"]),
    ...mapActions("settingsPaymentsStore", ["sendStripeToken", "getAllPaymentCards"]),
    stripeTokenSubmitter(token) {
      this.setFormState(false);
      this.isBlocked = false;
      this.sendStripeToken(token).then(() => {
        this.getAllPaymentCards();
      });
    },
    stripeTokenHandler($event) {
      this.isBlocked = true;
      $event.preventDefault();
      this.stripe.createToken(this.card).then(res => {
        if (res.error) {
          const errorElement = document.getElementById("card-errors");
          errorElement.textContent = res.error.message;
          this.isBlocked = false;
        } else {
          this.stripeTokenSubmitter(res.token);
        }
      });
    },
    createStripeElement() {
      this.stripe = window.Stripe(process.env.VUE_APP_STRIPE_KEY, {
        locale: checkCurrentLanguage()
      });
      this.elements = this.stripe.elements({});
      this.card = this.elements.create("card", {
        hidePostalCode: true,
        iconStyle: "solid",
        style: {
          base: {
            iconColor: "#005FCE",
            color: "#000000",
            fontWeight: 500,
            fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
            fontSize: "15px",
            fontSmoothing: "antialiased",
            ":-webkit-autofill": {
              color: "#fce883"
            },
            "::placeholder": {
              color: "#cecece"
            }
          },
          complete: {
            iconColor: "#6D9B69"
          },
          invalid: {
            iconColor: "#e25950",
            color: "#e25950"
          }
        }
      });
      this.card.mount("#card-element");
      this.card.addEventListener("change", event => {
        if (event.error) {
          this.$refs.cardErrors.textContent = event.error.message;
        } else {
          this.$refs.cardErrors.textContent = "";
        }
      });
    }
  },
  mounted() {
    this.createStripeElement();
  }
};
</script>

<style scoped>
.add-card-form__input-cont {
  display: block;
  width: 100%;
  margin: 20px 0;
  padding: 20px;
  border: 2px solid rgb(33, 33, 33);
  border-radius: 12px;
}
.add-card-form__btn {
  display: inline-block;
  width: auto;
  padding: 0 20px;
  margin-left: 20px;
  height: 58px;
  line-height: 54px;
  font-size: 24px;
  border: 2px solid #005fce;
  border-radius: 12px;
}
</style>
