import { mapState, mapActions } from "vuex";
import NotifTile from "@/components/Account/Notifications/NotificationTile.vue";
import AccountPage from "@/views/AccountPageSkeleton.vue";

export default {
  components: {
    "account-page": AccountPage,
    "notif-tile": NotifTile
  },
  data() {
    return {
      pageTitle: this.$t("Notifications")
    };
  },
  computed: {
    ...mapState("AllNotifsStore", ["allNotifs"])
  },
  methods: {
    ...mapActions("AllNotifsStore", ["getAllNotifs"])
  },
  mounted() {
    this.getAllNotifs();
  }
};
