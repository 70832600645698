<template>
  <section class="settings-section">
    <h2 class="settings-section__title">{{ title }}</h2>
    <div class="settings-section__content">
      <slot></slot>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    title: {
      type: String
    }
  }
};
</script>

<style scoped>
.settings-section {
  display: block;
  width: 100%;
  padding: 0 10px 35px;
}
.settings-section__title {
  display: block;
  width: 100%;
  padding: 5px 10px;
  font-size: 24px;
  font-weight: normal;
  border-bottom: 1px solid #dfdfdf;
}
.settings-section__content {
  display: block;
  width: 100%;
  padding: 25px 0;
}
</style>
