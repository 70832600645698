<template>
  <article class="account-page">
    <h1 class="account-page__title">{{ title }}</h1>
    <slot></slot>
  </article>
</template>

<script>
export default {
  props: {
    title: {
      type: String
    }
  }
};
</script>

<style scoped>
.account-page {
  display: block;
  width: 100%;
  padding: 40px 30px 70px;
  text-align: left;
}
.account-page__title {
  display: block;
  width: 100%;
  padding: 0 10px;
  font-size: 48px;
  font-weight: normal;
}
@media (max-width: 1024px) {
  .account-page {
    padding: 10px;
  }
}
@media (max-width: 767px) {
  .account-page {
    padding: 0;
  }
  .account-page__title {
    font-size: 35px;
  }
}
</style>
