<template>
  <v-dialog v-model="passFormOpened" persistent max-width="600px">
    <v-card>
      <v-card-title>
        <span class="headline">{{ $t("Change password") }}</span>
      </v-card-title>
      <v-container>
        <form>
          <v-text-field v-model="oldPass" type="password" placeholder="Old password" solo />
          <v-text-field
            v-model.trim="newPass"
            :label="$t(`New password`)"
            :type="showPass ? 'text' : 'password'"
            :rules="[rules.password.required]"
            placeholder="New password"
            @click:append="showPass = !showPass"
            :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
            solo
          />
          <v-text-field
            v-model.trim="confPass"
            :label="$t(`Confirm new password`)"
            :type="showPass ? 'text' : 'password'"
            :rules="[rules.passwordConfirmation.required, passwordConfirmationRule]"
            placeholder="Confirm password"
            @click:append="showPass = !showPass"
            :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
            solo
          />
          <v-row no-gutters v-for="error in errors" :key="error">
            <v-col no-gutters>
              <p class="text-center red--text">{{ error }}</p>
            </v-col>
          </v-row>
          <div class="change-pass-form__btns-wrapper">
            <v-btn
              color="primary"
              outlined
              class="change-pass-form__btn"
              type="button"
              @click="setPassFormState(false, $event)"
            >
              {{ $t("Cancel") }}
            </v-btn>
            <v-btn color="primary" outlined class="change-pass-form__btn" @click="changePass">
              {{ $t("Save") }}
            </v-btn>
          </div>
        </form>
      </v-container>
    </v-card>
    <errors-snackbar :show.sync="showWarnings" :errors="warnings"> </errors-snackbar>
    <info-snackbar :show.sync="showInfo" :message="infoMessage"> </info-snackbar>
  </v-dialog>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import ErrorsSnackbar from "@/components/ErrorsSnackbar.vue";
import InfoSnackbar from "@/components/InfoSnackbar.vue";
import { sendChangePassData } from "@/api";

export default {
  components: { ErrorsSnackbar, InfoSnackbar },
  data() {
    return {
      oldPass: "",
      newPass: "",
      confPass: "",
      showPass: false,
      errors: [],
      showWarnings: false,
      showInfo: false,
      infoMessage: "",
      warnings: [],
      rules: {
        password: {
          required: value => !!value || this.$t("Required.")
        },
        passwordConfirmation: {
          required: value => !!value || this.$t("Required.")
        }
      }
    };
  },
  computed: {
    passwordConfirmationRule() {
      return () => this.newPass === this.confPass || this.$t("Password must match");
    },
    ...mapState("settingsGeneralStore", ["passFormOpened"])
  },
  methods: {
    ...mapMutations("settingsGeneralStore", ["setPassFormState"]),
    ...mapActions("settingsGeneralStore", ["sendChangePassData"]),
    changePass() {
      const { oldPass, newPass, confPass } = this;
      sendChangePassData({ oldPass, newPass, confPass })
        .then(() => {
          // eslint-disable-next-line @typescript-eslint/no-this-alias
          const self = this;
          self.showWarnings = false;
          self.infoMessage = this.$t("Password has been successfully changed");
          self.showInfo = true;
          setTimeout(function() {
            self.setPassFormState(false);
          }, 2000);
        })
        .catch(err => {
          this.warnings =
            err.response.status !== 500 ? err.response.data.errors : [err.response.data.error];
          this.showWarnings = true;
        });
    }
  }
};
</script>

<style scoped>
.change-pass-form__btn {
  display: inline-block;
  width: auto;
  padding: 0 20px;
  margin-left: 20px;
  height: 58px;
  line-height: 54px;
  font-size: 24px;
  border: 2px solid #005fce;
  border-radius: 12px;
}
</style>
