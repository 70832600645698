<template>
  <v-col md="4" ms="6" v-if="!this.is_deleted">
    <v-card class="notif-tile" :class="{ 'notif-tile--readed': this.data.read }">
      <span class="notif-delete" @click="removeNotification()">
        <i class="v-icon notranslate mdi mdi-delete theme--light"></i>
      </span>
      <span :class="`notif-tile__color-label--${tileColor}`" class="notif-tile__color-label"></span>
      <div class="notif-tile__content">
        <div v-if="isRequestTile">
          <div class="notif-tile__request-author-wrapper">
            <request-author :author="getAuthor || {}" />
            <div class="notif-tile__request-valid-date-cont">
              <p class="notif-tile__request-valid-date-label">{{ $t("Valid to") }}</p>
              <p class="notif-tile__request-valid-date">
                {{ validTo }}
              </p>
            </div>
          </div>
          <p class="notif-tile__request-text">
            {{ msgText }}
            <a @click="goToTicket">{{ $t("Go to ticket") }}</a>
          </p>
        </div>

        <div v-else-if="isResponceTile">
          <p v-if="respondsAmount !== null" class="notif-tile__respond-counter">
            {{ respondsAmount }}
          </p>
          <p class="notif-tile__respond-text">{{ msgText }}</p>
        </div>

        <div v-else>
          <div class="notif-tile__msg-title-wrapper">
            <p v-if="msgTitle" class="notif-tile__msg-title">{{ msgTitle }}</p>
            <div v-if="authorData" class="notif-tile__msg-author-wrapper">
              <v-avatar color="#C4C4C4" :size="36">
                <img v-if="authorAvatar" :alt="authorName" :src="authorAvatar" />
                <v-icon v-else>mdi-account</v-icon>
              </v-avatar>
              <p v-if="authorName" class="notif-tile__msg-author-name">{{ authorName }}</p>
            </div>
          </div>

          <p v-if="msgText" class="notif-tile__msg-text">
            {{ msgText }}
            <template v-if="isNewMessageNotif">
              <a @click="goToTicket">
                {{ $t("Go to chat") }}
              </a>
            </template>
            <template v-if="!isNewMessageNotif">
              <a @click="goToTicket">{{ $t("Go to ticket") }}</a>
            </template>
          </p>

          <p v-if="msgSecondaryText" class="notif-tile__msg-secondary-text">
            {{ msgSecondaryText }}
          </p>
        </div>
      </div>

      <p class="notif-tile__msg-date">{{ msgDate }}</p>
    </v-card>
  </v-col>
</template>

<script>
import moment from "moment";
import RequestAuthor from "./NotificationTileRequestAuthor";
import { deleteNotification, readNotification } from "@/api";

export default {
  components: {
    "request-author": RequestAuthor
  },
  data: () => ({
    is_deleted: false
  }),
  props: {
    data: Object
  },
  methods: {
    removeNotification() {
      deleteNotification(this.data.id)
        .then(r => {
          console.log(r);
        })
        .catch(e => {
          console.log(e);
        });
      this.is_deleted = true;
    },

    goToTicket() {
      readNotification(this.data.id);
      let link = "";
      if (this.isRequestTile) {
        link = "/bid/" + this.data.quote_request_id;
      } else if (this.isExpiredTicketNotif) {
        link = "/ticket/" + this.data.ticket_id;
      } else if (this.isServiceReportUploadedNotify || this.isInvoiceSentNotify) {
        link = "/ticket/" + this.data.ticket_id + "/quoterequest/" + this.data.quote_request_id;
      } else if (this.isOrderAbortedNotif) {
        link = "/bid/" + this.data.quote_request_id;
      } else if (this.isResponceTile || this.isNewQuotationNotif) {
        link = "/ticket/" + this.data.ticket_id;
      } else if (this.isNewMessageNotif) {
        if (this.data.sender.user_profile === "CustomerProfile") {
          link = "/bid/" + this.data.quote_request_id + "#chat";
        } else {
          link =
            "/ticket/" +
            this.data.ticket_id +
            "/quoterequest/" +
            this.data.quote_request_id +
            "#chat";
        }
      } else if (
        this.isPoSentForSignNotif ||
        this.isInvoicePaidNotify ||
        this.isServiceReportApprovedNotify ||
        this.isWarrantyRequestedNotif
      ) {
        link = "/bid/" + this.data.quote_request_id;
      } else if (this.isPoSignedNotif) {
        link = "/ticket/" + this.data.ticket_id + "/quoterequest/" + this.data.quote_request_id;
      } else {
        link = "/ticket/" + this.data.ticket_id;
      }

      this.$router.push(link);
    }
  },
  computed: {
    getAuthor() {
      return this.data.author || this.data.sender;
    },
    tileColor() {
      return this.data.color;
    },
    isRequestTile() {
      return this.data.type === "new_quote_requested";
    },
    isResponceTile() {
      return this.data.type === "respond";
    },
    isExpiredTicketNotif() {
      return this.data.type === "order_timer_expires";
    },
    isNewMessageNotif() {
      return this.data.type === "new_message";
    },
    isServiceReportUploadedNotify() {
      return this.data.type === "service_report_uploaded";
    },
    isServiceReportApprovedNotify() {
      return this.data.type === "service_report_approved";
    },
    isInvoiceSentNotify() {
      return this.data.type === "invoice_sent";
    },
    isInvoicePaidNotify() {
      return this.data.type === "invoice_paid";
    },
    isOrderAbortedNotif() {
      return this.data.type === "order_aborted";
    },
    isNewQuotationNotif() {
      return this.data.type === "new_quotation";
    },
    isPoSentForSignNotif() {
      return this.data.type === "po_sent_for_signing";
    },
    isPoSignedNotif() {
      return this.data.type === "po_signed";
    },
    isWarrantyRequestedNotif() {
      return this.data.type === "warranty_requested";
    },
    msgDate() {
      // return moment(`${this.data.date} ${this.data.date}`).format("HH:mm d/MM/YYYY");
      return moment(this.data.date).format("DD/MM/YYYY HH:mm");
    },
    msgTitle() {
      return this.data.title;
    },
    msgText() {
      return this.data.text_content;
    },
    msgSecondaryText() {
      return this.data.secondaryText;
    },
    authorData() {
      return this.data.author || "";
    },
    authorAvatar() {
      return this.data.author.avatar || "";
    },
    authorName() {
      return this.data.author.name || "";
    },
    respondsAmount() {
      return this.data.amount === undefined ? null : this.data.amount;
    },
    validTo() {
      return this.data.valid_to ? moment(this.data.valid_to).format("DD/MM/YYYY HH:mm") : "";
    }
  }
};
</script>

<style scoped lang="scss">
.notif-tile {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-width: 430px;
  padding: 10px 25px 20px 40px;
  background: #ffffff;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  &--readed {
    .notif-tile__color-label {
      opacity: 0.2;
    }
  }
}
.notif-tile__color-label {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 20px;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}
.v-card > span.notif-tile__color-label {
  /* rewrite vuetify v-card  styles*/
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0;
}
.notif-tile__color-label--yellow {
  background-color: #ffa800;
}
.notif-tile__color-label--green {
  background-color: #4da046;
}
.notif-tile__color-label--red {
  background-color: #e00000;
}
.notif-tile__color-label--blue {
  background-color: #3d89e3;
}
.notif-tile__content {
  display: block;
  flex-grow: 1;
  width: 100%;
}
.notif-tile__msg-date {
  display: block;
  flex-shrink: 0;
  width: 100%;
  margin-bottom: 0;
  font-size: 14px;
  color: #aeaeae;
}
.notif-tile__msg-title-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.notif-tile__msg-title,
.notif-tile__msg-text,
.notif-tile__msg-secondary-text {
  display: block;
  width: 100%;
  margin-bottom: 10px;
  font-size: 16px;
}
.notif-tile__msg-title {
  display: inline-block;
  width: auto;
  flex-shrink: 0;
}
.notif-tile__msg-title:only-child {
  margin-top: 10px;
}
.notif-tile__msg-text {
  font-weight: bold;
}
.notif-tile__msg-secondary-text {
  margin-top: 10px;
}
.notif-tile__msg-author-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-grow: 1;
  margin-bottom: 20px;
}
.notif-tile__msg-author-name {
  margin-bottom: 0;
  padding-left: 10px;
  font-size: 20px;
}
.notif-tile__respond-counter {
  display: block;
  width: 100%;
  margin-bottom: 10px;
  font-size: 36px;
  font-weight: bold;
}
.notif-tile__respond-text,
.notif-tile__request-text {
  display: block;
  width: 100%;
  font-size: 20px;
}
.notif-tile__request-author-wrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.notif-tile__request-valid-date-cont {
  display: block;
  flex-shrink: 0;
  font-size: 14px;
  text-align: right;
}
.notif-tile__request-valid-date,
.notif-tile__request-valid-date-label {
  display: block;
  width: 100%;
  margin-bottom: 0;
}
.notif-tile__request-valid-date-label {
  color: #aeaeae;
}

.notif-delete {
  position: absolute;
  right: 5px;
  top: 5px;
  cursor: pointer;
}

@media (max-width: 960px) {
  .notif-tile {
    max-width: initial;
  }
}
</style>
