import AccountPage from "@/views/AccountPageSkeleton.vue";
import SettingsSection from "@/components/Account/Settings/SettingsSection.vue";
import SettingsGeneralForm from "@/components/Account/Settings/General/SettingsGeneralForm.vue";
import SettingsNotificationsForm from "@/components/Account/Settings/Notifications/SettingsNotificationsForm.vue";
import SettingsPaymentMethods from "@/components/Account/Settings/Payments/SettingsPaymentMethods.vue";
import SettingsPayouts from "@/components/Account/Settings/Payouts/SettingsPayouts.vue";

export default {
  components: {
    "account-page": AccountPage,
    "settings-section": SettingsSection,
    "general-form": SettingsGeneralForm,
    "notifs-form": SettingsNotificationsForm,
    payments: SettingsPaymentMethods,
    payouts: SettingsPayouts
  },
  data() {
    return {
      settingsSections: [
        { title: this.$t("General"), name: "general", component: "general-form" },
        { title: this.$t("Notifications"), name: "notifs", component: "notifs-form" },
        { title: this.$t("Payment method"), name: "payments", component: "payments" },
        { title: this.$t("Payouts"), name: "payouts", component: "payouts" }
      ]
    };
  }
};
