<template>
  <div class="general-form">
    <!-- <div class="general-form__row">
      <p class="general-form__label">{{ $t("My email") }}</p>
      <v-text-field type="email" readonly placeholder="My email" solo class="general-form__input" />
      <v-btn
        color="primary"
        outlined
        @click="setEmailFormState(true, $event)"
        class="general-form__btn general-form__btn--with-margin"
      >
        {{ $t("Change") }}
      </v-btn>
      <change-email-form />
    </div>
    <div class="general-form__row">
      <p class="general-form__label">{{ "Home page" }}</p>
      <v-combobox solo class="general-form__select"></v-combobox>
    </div> -->
    <div class="general-form__row">
      <v-btn
        color="primary"
        outlined
        class="general-form__btn"
        @click="setPassFormState(true, $event)"
      >
        {{ $t("Change password") }}
      </v-btn>
      <change-pass-form />
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
// import ChangeEmailModal from "./SettingsChangeEmailModal";
import ChangPasslModal from "./SettingsChangPasslModal";

export default {
  components: {
    // "change-email-form": ChangeEmailModal,
    "change-pass-form": ChangPasslModal
  },
  computed: {
    ...mapState("settingsGeneralStore", ["email", "currentHomePage"])
  },
  methods: {
    ...mapMutations("settingsGeneralStore", ["setEmailFormState", "setPassFormState"])
  }
};
</script>

<style scoped>
.general-form {
  display: block;
  width: 100%;
}
.general-form__row {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.general-form__label {
  display: block;
  width: 100%;
  margin-bottom: 10px;
  padding-left: 25px;
  font-size: 14px;
  color: #aeaeae;
}
.general-form__input,
.general-form__select {
  max-width: 555px;
}
.general-form__btn {
  display: inline-block;
  width: auto;
  padding: 0 20px;
  height: 58px;
  line-height: 54px;
  font-size: 24px;
  border: 2px solid #005fce;
  border-radius: 12px;
}
.general-form__btn--with-margin {
  margin-left: 20px;
}
@media (max-width: 768px) {
  .general-form__input,
  .general-form__select {
    max-width: initial;
    width: 100%;
  }
  .general-form__btn--with-margin {
    margin-left: 0;
    margin-bottom: 15px;
  }
}
</style>
