<template>
  <header class="cm-usual-header">
    <div class="cm-usual-header__title-cont">
      <p class="cm-usual-header__title">{{ title }}</p>
      <steps
        :count="stepsCount"
        v-if="!hideSteps"
        :disabled="enableSteps"
        :active.sync="headerStep"
      ></steps>
    </div>
    <p class="cm-usual-header__number">{{ number }}</p>
    <!-- <button class="cm-usual-header__close-btn" type="button"></button> -->
  </header>
</template>

<script>
import Steps from "@/components/Steps";

export default {
  components: {
    steps: Steps
  },
  computed: {
    headerStep: {
      get() {
        return this.step;
      },
      set(val) {
        this.$emit("update:step", val);
      }
    }
  },
  props: {
    title: {
      type: String
    },
    step: {
      type: Number
    },
    number: {
      type: String
    },
    stepsCount: {
      type: Number,
      default: 2
    },
    hideSteps: {
      type: Boolean,
      default: false
    },
    enableSteps: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style scoped>
.cm-usual-header {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  padding: 40px 40px 0;
}
.cm-usual-header__title-cont {
  display: flex;
  align-items: center;
  width: auto;
}
.cm-usual-header__title {
  display: inline-block;
  width: auto;
  margin-right: 30px;
  margin-bottom: -10px;
  font-size: 24px;
  font-weight: bold;
}
.cm-usual-header__number {
  display: inline-block;
  width: auto;
  margin-bottom: 0;
  font-size: 20px;
  font-weight: bold;
}
.cm-usual-header__close-btn {
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  width: 40px;
  height: 40px;
  background-image: url(~@/assets/icons/close_cross.svg);
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 16px 16px;
}
</style>
