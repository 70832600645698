<template>
  <div :class="{ 'payment-card--primary': data.primary }" class="payment-card">
    <p v-if="data.primary" class="payment-card__primary-label">{{ $t("Primary") }}</p>
    <div class="payment-card__actions-cont">
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <button class="payment-card__actions-btn" v-bind="attrs" v-on="on">
            <span></span>
          </button>
        </template>
        <v-list>
          <v-list-item @click="$emit('cardaction', { act: 'primary', id: data.id })">
            <v-list-item-title>{{ $t("Make primary") }}</v-list-item-title>
          </v-list-item>
          <v-list-item @click="$emit('cardaction', { act: 'delete', id: data.id })">
            <v-list-item-title>{{ $t("Delete") }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <!-- <button class="payment-card__actions-btn">
        <span></span>
      </button> -->
      <div class="payment-card__actions-list"></div>
    </div>
    <p class="payment-card__name">{{ data.name }}</p>
    <p class="payment-card__card">* * * {{ data.last_4 }}</p>
    <p class="payment-card__expiry">{{ formatedExpiry }}</p>
  </div>
</template>

<script>
import moment from "moment";
export default {
  props: {
    data: {
      type: Object
    }
  },
  data() {
    return {
      optionsOpened: false
    };
  },
  computed: {
    formatedExpiry() {
      return moment(this.data.expiry, "YYYY-MM").format("MM / YY");
    }
  }
};
</script>

<style scoped>
.payment-card {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  height: 210px;
  padding: 20px 36px;
  color: #ffffff;
  background-color: #999999;
  border-radius: 18px;
}
.payment-card--primary {
  background-color: #6d9b69;
}
.payment-card__primary-label {
  position: absolute;
  top: 18px;
  right: 50px;
  font-size: 14px;
  font-weight: bold;
  color: #ffffff;
}
.payment-card__actions-cont {
  position: absolute;
  top: 0;
  right: 0;
}
.payment-card__actions-btn {
  position: relative;
  display: block;
  width: 50px;
  height: 40px;
}
.payment-card__actions-btn:after,
.payment-card__actions-btn:before,
.payment-card__actions-btn span {
  content: "";
  position: absolute;
  right: 20px;
  display: block;
  width: 4px;
  height: 4px;
  background-color: #ffffff;
  border-radius: 50%;
}
.payment-card__actions-btn:after {
  top: 20px;
}
.payment-card__actions-btn:before {
  top: 27px;
}
.payment-card__actions-btn span {
  top: 34px;
}
.payment-card__actions-list {
  position: absolute;
  right: -10px;
  top: calc(100% + 10px);
  display: none;
  width: auto;
  padding: 25px 25px 10px 15px;
  white-space: nowrap;
  color: #000000;
  background-color: #ffffff;
}
.payment-card__actions-btn:focus {
  outline: none;
}
.payment-card__actions-btn:focus + .payment-card__actions-list {
  display: inline-block;
}
.payment-card__actions-list:before {
  content: "";
  position: absolute;
  top: -4px;
  right: 26px;
  display: block;
  width: 14px;
  height: 14px;
  transform: rotate(-45deg);
  background-color: #ffffff;
}
.payment-card__actions-list-item {
  display: block;
  width: 100%;
  font-size: 16px;
  line-height: 40px;
  text-align: right;
}
.payment-card__name,
.payment-card__card {
  margin-bottom: 15px;
  font-size: 18px;
}
.payment-card__card {
  font-weight: 500;
}
.payment-card__expiry {
  font-size: 14px;
}
</style>
