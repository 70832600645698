<template>
  <div class="request-author">
    <v-avatar color="#C4C4C4" :size="60">
      <img v-if="authorAvatar" :alt="authorName" :src="authorAvatar" />
      <v-icon v-else>mdi-account</v-icon>
    </v-avatar>
    <div class="request-author__name-cont">
      <p class="request-author__name">{{ authorName }}</p>
      <div class="request-author__rating-cont">
        <v-rating
          :background-color="'#C4C4C4'"
          :color="'#C4C4C4'"
          :readonly="true"
          :value="authorRating"
          x-small
          dense
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    author: {
      type: Object
    }
  },
  computed: {
    authorAvatar() {
      return this.author.avatar || "";
    },
    authorName() {
      return this.author.name || "";
    },
    authorRating() {
      return +this.author.rating || 0;
    }
  }
};
</script>

<style scoped>
.request-author {
  display: flex;
  align-items: center;
  width: auto;
}
.request-author__name-cont {
  display: block;
  padding-left: 20px;
}
.request-author__name {
  display: block;
  width: 100%;
  margin-bottom: 0;
  font-size: 20px;
}
.request-author__rating-cont {
  display: block;
  width: 90px;
}
</style>
