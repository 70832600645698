<template>
  <div class="notifs-form">
    <section class="notifs-form__section">
      <h3 class="notifs-form__section-title notifs-form__section-title--with-padding">
        {{ $t("Select notifications") }}
      </h3>
      <v-checkbox
        v-for="notif in allNotifs"
        :key="notif.id"
        :label="notif.name"
        :value="notif.id"
        v-model="innerNotifs"
      />
    </section>
    <section class="notifs-form__section">
      <h3 class="notifs-form__section-title">{{ $t("Select options") }}</h3>
      <v-switch
        v-for="opt in allOptions"
        :key="opt.id"
        :label="opt.name"
        :value="opt.id"
        v-model="innerOptions"
        class="notifs-form__toggler"
        multiple
      />
    </section>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";

export default {
  data() {
    return {
      allNotifs: [
        { id: "new_quote_requested", name: this.$t("New quote requested") },
        { id: "application_approved", name: this.$t("Application approved") },
        { id: "order_aborted", name: this.$t("Order aborted") },
        { id: "invoice_paid", name: this.$t("Invoice paid") },
        { id: "new_message", name: this.$t("New message") }
      ],
      allOptions: [
        { id: "inapp", name: this.$t("Inapp notifications") },
        { id: "email", name: this.$t("Email notifications") },
        { id: "sms", name: this.$t("SMS notifications") }
      ]
    };
  },
  computed: {
    ...mapState("settingsNotificationsStore", ["notifs", "options"]),
    innerNotifs: {
      get() {
        const res = this.notifs.map(item => {
          if (item[2]) {
            return item[1];
          }
        });
        return res;
      },
      set(vals) {
        this.notifs.map(item => {
          item[2] = vals.indexOf(item[1]) != -1;
          return item;
        });
        this.updateNotifFlag(this.findChanged(this.innerNotifs, vals));
      }
    },
    innerOptions: {
      get() {
        const res = [];
        if (this.options.inapp) res.push("inapp");
        if (this.options.email) res.push("email");
        if (this.options.sms) res.push("sms");
        return res;
      },
      set(vals) {
        // const params = {
        //   id: this.options.id,
        //   inapp: vals.indexOf("inapp") != -1,
        //   email: vals.indexOf("email") != -1,
        //   sms: vals.indexOf("sms") != -1
        // };
        this.setOptions(vals);
      }
    }
  },
  methods: {
    ...mapMutations("settingsNotificationsStore", ["setNotif", "setOptions"]),
    ...mapActions("settingsNotificationsStore", [
      "getSettingsNotifsFlags",
      "updateNotifFlag",
      "getSettingsChanels",
      "updateChanels"
    ]),
    findChanged(oldArr, newArr) {
      let id, active;
      for (const item of this.notifs) {
        if (oldArr.indexOf(item[1]) == -1 && newArr.indexOf(item[1]) != -1) {
          id = item[0];
          active = true;
          return { id, active };
        } else if (oldArr.indexOf(item[1]) != -1 && newArr.indexOf(item[1]) == -1) {
          id = item[0];
          active = false;
          return { id, active };
        }
      }
    }
  },
  mounted() {
    this.getSettingsNotifsFlags();
    this.getSettingsChanels();
  }
};
</script>

<style>
.notifs-form__toggler .v-input__slot {
  flex-direction: row-reverse;
  width: fit-content;
}
.notifs-form__toggler.v-input--selection-controls .v-input__slot > .v-label {
  flex-grow: 0;
  padding-right: 10px;
}
</style>

<style scoped>
.notifs-form {
  display: flex;
  width: 100%;
}
.notifs-form__section {
  display: block;
  width: 100%;
  max-width: 420px;
}
.notifs-form__section-title {
  display: block;
  width: 100%;
  font-size: 14px;
  font-weight: normal;
}
.notifs-form__section-title--with-padding {
  padding-left: 30px;
}
</style>
