import { getFinanceAccount } from "@/api";
import { getUserInfo } from "@/auth/auth";
import SettingsPayoutsModal from "@/components/Account/Settings/Payouts/SettingsPayoutsModal.vue";

export default {
  components: { SettingsPayoutsModal },
  data: () => ({
    userInfo: getUserInfo() || {},
    isPayoutModalOpened: false,
    financeAccount: null
  }),
  computed: {},
  mounted() {
    getFinanceAccount(this.userInfo.id).then(r => {
      this.financeAccount = r.data || null;
    });
  },
  methods: {}
};
