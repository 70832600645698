import UsualHeader from "@/components/ContractModals/Components/UsualHeader.vue";
import { StripePayout as StripePayoutInterface } from "@/components/Account/Settings/Payouts/SettingsPayouts.interface";
import { getStripeAccountStatus, getStripeUpdateLink, sendStripeAccount } from "@/api";
import ErrorsSnackbar from "@/components/ErrorsSnackbar.vue";

const STRIPE: StripePayoutInterface = {
  first_name: "",
  last_name: "",
  birthday: null,
  website: null,
  industries: null,
  bank_country: "",
  address: "",
  city: "",
  state: "",
  zip: "",
  country: "",
  currency: "",
  ssn: "",
  ban: "",
  routing_number: "",
  email: "",
  phone: "",
  ssn_last_four: "",
  id_card_front: null,
  id_card_back: null,
  terms: false
};

export default {
  components: {
    "usual-header": UsualHeader,
    ErrorsSnackbar
  },
  name: "settings-payouts-modal",
  props: {
    financeAccount: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    submitForm() {
      const formData = new FormData();
      for (const key in this.stripeInformation) {
        if (key === "birthday") {
          for (const bDay in this.stripeInformation[key]) {
            const keyName = `${key}[${bDay}]`;
            formData.append(keyName, this.stripeInformation[key][bDay]);
          }
        } else {
          if (this.stripeInformation[key] && this.stripeInformation[key].length) {
            formData.append(key, this.stripeInformation[key]);
          }
        }
      }

      sendStripeAccount(formData).then(r => {
        window.open(r.data.connect_url, "_blank");
      });
    },
    changeDate(date) {
      const dateObject = date.split("-");
      this.stripeInformation["birthday"] = {
        year: dateObject[0],
        month: dateObject[1],
        day: dateObject[2]
      };
    },
    getStripeInfo() {
      getStripeAccountStatus().then(r => {
        this.stripeIsActive = r.data.payouts_enabled;
      });
    },
    getStripeLinkToUpdate() {
      getStripeUpdateLink().then(r => {
        this.stripeConnectUrl = r.data.connect_url;
      });
    },
    updateStripeAccount() {
      if (this.stripeConnectUrl !== null && this.stripeConnectUrl.length) {
        document.location.href = this.stripeConnectUrl;
      } else {
        this.warnings = [this.$t("You can't update your stripe profile")];
        this.showWarnings = true;
      }
    }
  },
  computed: {
    isSSNAvailable() {
      return this.stripeInformation.currency === "USD";
    },
    availableCurrencies() {
      if (this.stripeInformation.bank_country === "US") {
        return [{ value: "USD", title: "USD" }];
      } else {
        return [{ value: "CAD", title: "CAD" }];
      }
    }
  },
  mounted() {
    this.getStripeInfo();
    this.getStripeLinkToUpdate();
  },
  data() {
    return {
      rules: {
        counter: value => (value && value.length <= 100) || "Max 100 characters",
        onlyDigits: value => /^\d*$/.test(value) || "Input is invalid",
        textInput: value => {
          const pattern = /[^\da-zA-Z-+@, ]/;
          return !pattern.test(value) || "Input is invalid";
        },
        numberAndText: value => {
          const pattern = /[^\da-zA-Z]/;
          return !pattern.test(value) || "Input is invalid";
        },
        numberAndTextDefis: value => {
          const pattern = /[^\da-zA-Z-]/;
          return !pattern.test(value) || "Input is invalid";
        },
        zip: value => {
          const pattern = /[^\da-zA-Z ]/;
          return !pattern.test(value) || "Input is invalid";
        },
        phone: value => {
          const pattern = /[^\d+]/;
          return !pattern.test(value) || "Phone is invalid";
        },
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        }
      },
      warnings: [],
      showWarnings: false,
      stripeIsActive: false,
      stripeConnectUrl: "",
      newSpecialities: "",
      stripeInformation: STRIPE,
      activePicker: null,
      date: null,
      menu: false,
      step: 1,
      isModalOpened: false,
      currencies: [
        { value: "USD", title: "USD" },
        { value: "CAD", title: "CAD" }
      ],
      countries: [
        { value: "US", title: "United States" },
        { value: "CA", title: "Canada" }
      ],
      industries: [
        { title: "A/C, Refrigeration Repair", value: "7623" },
        { title: "Accounting/Bookkeeping Services", value: "8931" },
        { title: "Advertising Services", value: "7311" },
        { title: "Agricultural Cooperative", value: "0763" },
        { title: "Airlines, Air Carriers", value: "4511" },
        { title: "Airports, Flying Fields", value: "4582" },
        { title: "Ambulance Services", value: "4119" },
        { title: "Amusement Parks/Carnivals", value: "7996" },
        { title: "Antique Reproductions", value: "5937" },
        { title: "Antique Shops", value: "5932" },
        { title: "Aquariums", value: "7998" },
        { title: "Architectural/Surveying Services", value: "8911" },
        { title: "Art Dealers and Galleries", value: "5971" },
        { title: "Artists Supply and Craft Shops", value: "5970" },
        { title: "Auto Body Repair Shops", value: "7531" },
        { title: "Auto Paint Shops", value: "7535" },
        { title: "Auto Service Shops", value: "7538" },
        { title: "Auto and Home Supply Stores", value: "5531" },
        { title: "Automated Cash Disburse", value: "6011" },
        { title: "Automated Fuel Dispensers", value: "5542" },
        { title: "Automobile Associations", value: "8675" },
        { title: "Automotive Parts and Accessories Stores", value: "5533" },
        { title: "Automotive Tire Stores", value: "5532" },
        {
          title:
            "Bail and Bond Payments (payment to the surety for the bond, not the actual bond paid to the government agency)",
          value: "9223"
        },
        { title: "Bakeries", value: "5462" },
        { title: "Bands, Orchestras", value: "7929" },
        { title: "Barber and Beauty Shops", value: "7230" },
        { title: "Betting/Casino Gambling", value: "7995" },
        { title: "Bicycle Shops", value: "5940" },
        { title: "Billiard/Pool Establishments", value: "7932" },
        { title: "Boat Dealers", value: "5551" },
        { title: "Boat Rentals and Leases", value: "4457" },
        { title: "Book Stores", value: "5942" },
        { title: "Books, Periodicals, and Newspapers", value: "5192" },
        { title: "Bowling Alleys", value: "7933" },
        { title: "Bus Lines", value: "4131" },
        { title: "Business/Secretarial Schools", value: "8244" },
        { title: "Buying/Shopping Services", value: "7278" },
        { title: "Cable, Satellite, and Other Pay Television and Radio", value: "4899" },
        { title: "Camera and Photographic Supply Stores", value: "5946" },
        { title: "Candy, Nut, and Confectionery Stores", value: "5441" },
        { title: "Car Rental Agencies", value: "7512" },
        { title: "Car Washes", value: "7542" },
        {
          title: "Car and Truck Dealers (New & Used) Sales, Service, Repairs Parts and Leasing",
          value: "5511"
        },
        {
          title: "Car and Truck Dealers (Used Only) Sales, Service, Repairs Parts and Leasing",
          value: "5521"
        },
        { title: "Carpentry Services", value: "1750" },
        { title: "Carpet/Upholstery Cleaning", value: "7217" },
        { title: "Caterers", value: "5811" },
        { title: "Charitable and Social Service Organizations - Fundraising", value: "8398" },
        { title: "Chemicals and Allied Products (Not Elsewhere Classified)", value: "5169" },
        { title: "Child Care Services", value: "8351" },
        { title: "Childrens and Infants Wear Stores", value: "5641" },
        { title: "Chiropodists, Podiatrists", value: "8049" },
        { title: "Chiropractors", value: "8041" },
        { title: "Cigar Stores and Stands", value: "5993" },
        { title: "Civic, Social, Fraternal Associations", value: "8641" },
        { title: "Cleaning and Maintenance", value: "7349" },
        { title: "Clothing Rental", value: "7296" },
        { title: "Colleges, Universities", value: "8220" },
        { title: "Commercial Equipment (Not Elsewhere Classified)", value: "5046" },
        { title: "Commercial Footwear", value: "5139" },
        { title: "Commercial Photography, Art and Graphics", value: "7333" },
        { title: "Commuter Transport, Ferries", value: "4111" },
        { title: "Computer Network Services", value: "4816" },
        { title: "Computer Programming", value: "7372" },
        { title: "Computer Repair", value: "7379" },
        { title: "Computer Software Stores", value: "5734" },
        { title: "Computers, Peripherals, and Software", value: "5045" },
        { title: "Concrete Work Services", value: "1771" },
        { title: "Construction Materials (Not Elsewhere Classified)", value: "5039" },
        { title: "Consulting, Public Relations", value: "7392" },
        { title: "Correspondence Schools", value: "8241" },
        { title: "Cosmetic Stores", value: "5977" },
        { title: "Counseling Services", value: "7277" },
        { title: "Country Clubs", value: "7997" },
        { title: "Courier Services", value: "4215" },
        {
          title: "Court Costs, Including Alimony and Child Support - Courts of Law",
          value: "9211"
        },
        { title: "Credit Reporting Agencies", value: "7321" },
        { title: "Cruise Lines", value: "4411" },
        { title: "Dairy Products Stores", value: "5451" },
        { title: "Dance Hall, Studios, Schools", value: "7911" },
        { title: "Dating/Escort Services", value: "7273" },
        { title: "Dentists, Orthodontists", value: "8021" },
        { title: "Department Stores", value: "5311" },
        { title: "Detective Agencies", value: "7393" },
        { title: "Digital Goods Media – Books, Movies, Music", value: "5815" },
        { title: "Digital Goods – Applications (Excludes Games)", value: "5817" },
        { title: "Digital Goods – Games", value: "5816" },
        { title: "Digital Goods – Large Digital Goods Merchant", value: "5818" },
        { title: "Direct Marketing - Catalog Merchant", value: "5964" },
        { title: "Direct Marketing - Combination Catalog and Retail Merchant", value: "5965" },
        { title: "Direct Marketing - Inbound Telemarketing", value: "5967" },
        { title: "Direct Marketing - Insurance Services", value: "5960" },
        { title: "Direct Marketing - Other", value: "5969" },
        { title: "Direct Marketing - Outbound Telemarketing", value: "5966" },
        { title: "Direct Marketing - Subscription", value: "5968" },
        { title: "Direct Marketing - Travel", value: "5962" },
        { title: "Discount Stores", value: "5310" },
        { title: "Doctors", value: "8011" },
        { title: "Door-To-Door Sales", value: "5963" },
        { title: "Drapery, Window Covering, and Upholstery Stores", value: "5714" },
        { title: "Drinking Places", value: "5813" },
        { title: "Drug Stores and Pharmacies", value: "5912" },
        { title: "Drugs, Drug Proprietaries, and Druggist Sundries", value: "5122" },
        { title: "Dry Cleaners", value: "7216" },
        { title: "Durable Goods (Not Elsewhere Classified)", value: "5099" },
        { title: "Duty Free Stores", value: "5309" },
        { title: "Eating Places, Restaurants", value: "5812" },
        { title: "Educational Services", value: "8299" },
        { title: "Electric Razor Stores", value: "5997" },
        { title: "Electrical Parts and Equipment", value: "5065" },
        { title: "Electrical Services", value: "1731" },
        { title: "Electronics Repair Shops", value: "7622" },
        { title: "Electronics Stores", value: "5732" },
        { title: "Elementary, Secondary Schools", value: "8211" },
        { title: "Employment/Temp Agencies", value: "7361" },
        { title: "Equipment Rental", value: "7394" },
        { title: "Exterminating Services", value: "7342" },
        { title: "Family Clothing Stores", value: "5651" },
        { title: "Fast Food Restaurants", value: "5814" },
        { title: "Financial Institutions", value: "6012" },
        { title: "Fines - Government Administrative Entities", value: "9222" },
        { title: "Fireplace, Fireplace Screens, and Accessories Stores", value: "5718" },
        { title: "Floor Covering Stores", value: "5713" },
        { title: "Florists", value: "5992" },
        { title: "Florists Supplies, Nursery Stock, and Flowers", value: "5193" },
        { title: "Freezer and Locker Meat Provisioners", value: "5422" },
        { title: "Fuel Dealers (Non Automotive)", value: "5983" },
        { title: "Funeral Services, Crematories", value: "7261" },
        { title: "Furniture Repair, Refinishing", value: "7641" },
        {
          title: "Furniture, Home Furnishings, and Equipment Stores, Except Appliances",
          value: "5712"
        },
        { title: "Furriers and Fur Shops", value: "5681" },
        { title: "General Services", value: "1520" },
        { title: "Gift, Card, Novelty, and Souvenir Shops", value: "5947" },
        { title: "Glass, Paint, and Wallpaper Stores", value: "5231" },
        { title: "Glassware, Crystal Stores", value: "5950" },
        { title: "Golf Courses - Public", value: "7992" },
        { title: "Government Services (Not Elsewhere Classified)", value: "9399" },
        { title: "Grocery Stores, Supermarkets", value: "5411" },
        { title: "Hardware Stores", value: "5251" },
        { title: "Hardware, Equipment, and Supplies", value: "5072" },
        { title: "Health and Beauty Spas", value: "7298" },
        { title: "Hearing Aids Sales and Supplies", value: "5975" },
        { title: "Heating, Plumbing, A/C", value: "1711" },
        { title: "Hobby, Toy, and Game Shops", value: "5945" },
        { title: "Home Supply Warehouse Stores", value: "5200" },
        { title: "Hospitals", value: "8062" },
        { title: "Hotels, Motels, and Resorts", value: "7011" },
        { title: "Household Appliance Stores", value: "5722" },
        { title: "Industrial Supplies (Not Elsewhere Classified)", value: "5085" },
        { title: "Information Retrieval Services", value: "7375" },
        { title: "Insurance - Default", value: "6399" },
        { title: "Insurance Underwriting, Premiums", value: "6300" },
        { title: "Intra-Company Purchases", value: "9950" },
        { title: "Jewelry Stores, Watches, Clocks, and Silverware Stores", value: "5944" },
        { title: "Landscaping Services", value: "0780" },
        { title: "Laundries", value: "7211" },
        { title: "Laundry, Cleaning Services", value: "7210" },
        { title: "Legal Services, Attorneys", value: "8111" },
        { title: "Luggage and Leather Goods Stores", value: "5948" },
        { title: "Lumber, Building Materials Stores", value: "5211" },
        { title: "Manual Cash Disburse", value: "6010" },
        { title: "Marinas, Service and Supplies", value: "4468" },
        { title: "Masonry, Stonework, and Plaster", value: "1740" },
        { title: "Massage Parlors", value: "7297" },
        { title: "Medical Services", value: "8099" },
        { title: "Medical and Dental Labs", value: "8071" },
        {
          title: "Medical, Dental, Ophthalmic, and Hospital Equipment and Supplies",
          value: "5047"
        },
        { title: "Membership Organizations", value: "8699" },
        { title: "Mens and Boys Clothing and Accessories Stores", value: "5611" },
        { title: "Mens, Womens Clothing Stores", value: "5691" },
        { title: "Metal Service Centers", value: "5051" },
        { title: "Miscellaneous Apparel and Accessory Shops", value: "5699" },
        { title: "Miscellaneous Auto Dealers", value: "5599" },
        { title: "Miscellaneous Business Services", value: "7399" },
        {
          title: "Miscellaneous Food Stores - Convenience Stores and Specialty Markets",
          value: "5499"
        },
        { title: "Miscellaneous General Merchandise", value: "5399" },
        { title: "Miscellaneous General Services", value: "7299" },
        { title: "Miscellaneous Home Furnishing Specialty Stores", value: "5719" },
        { title: "Miscellaneous Publishing and Printing", value: "2741" },
        { title: "Miscellaneous Recreation Services", value: "7999" },
        { title: "Miscellaneous Repair Shops", value: "7699" },
        { title: "Miscellaneous Specialty Retail", value: "5999" },
        { title: "Mobile Home Dealers", value: "5271" },
        { title: "Motion Picture Theaters", value: "7832" },
        {
          title:
            "Motor Freight Carriers and Trucking - Local and Long Distance, Moving and Storage Companies, and Local Delivery Services",
          value: "4214"
        },
        { title: "Motor Homes Dealers", value: "5592" },
        { title: "Motor Vehicle Supplies and New Parts", value: "5013" },
        { title: "Motorcycle Shops and Dealers", value: "5571" },
        { title: "Motorcycle Shops, Dealers", value: "5561" },
        { title: "Music Stores-Musical Instruments, Pianos, and Sheet Music", value: "5733" },
        { title: "News Dealers and Newsstands", value: "5994" },
        { title: "Non-FI, Money Orders", value: "6051" },
        { title: "Non-FI, Stored Value Card Purchase/Load", value: "6540" },
        { title: "Nondurable Goods (Not Elsewhere Classified)", value: "5199" },
        { title: "Nurseries, Lawn and Garden Supply Stores", value: "5261" },
        { title: "Nursing/Personal Care", value: "8050" },
        { title: "Office and Commercial Furniture", value: "5021" },
        { title: "Opticians, Eyeglasses", value: "8043" },
        { title: "Optometrists, Ophthalmologist", value: "8042" },
        { title: "Orthopedic Goods - Prosthetic Devices", value: "5976" },
        { title: "Osteopaths", value: "8031" },
        { title: "Package Stores-Beer, Wine, and Liquor", value: "5921" },
        { title: "Paints, Varnishes, and Supplies", value: "5198" },
        { title: "Parking Lots, Garages", value: "7523" },
        { title: "Passenger Railways", value: "4112" },
        { title: "Pawn Shops", value: "5933" },
        { title: "Pet Shops, Pet Food, and Supplies", value: "5995" },
        { title: "Petroleum and Petroleum Products", value: "5172" },
        { title: "Photo Developing", value: "7395" },
        { title: "Photographic Studios", value: "7221" },
        { title: "Photographic, Photocopy, Microfilm Equipment, and Supplies", value: "5044" },
        { title: "Picture/Video Production", value: "7829" },
        { title: "Piece Goods, Notions, and Other Dry Goods", value: "5131" },
        { title: "Plumbing, Heating Equipment, and Supplies", value: "5074" },
        { title: "Political Organizations", value: "8651" },
        { title: "Postal Services - Government Only", value: "9402" },
        { title: "Precious Stones and Metals, Watches and Jewelry", value: "5094" },
        { title: "Professional Services", value: "8999" },
        {
          title:
            "Public Warehousing and Storage - Farm Products, Refrigerated Goods, Household Goods, and Storage",
          value: "4225"
        },
        { title: "Quick Copy, Repro, and Blueprint", value: "7338" },
        { title: "Railroads", value: "4011" },
        { title: "Real Estate Agents and Managers - Rentals", value: "6513" },
        { title: "Record Stores", value: "5735" },
        { title: "Recreational Vehicle Rentals", value: "7519" },
        { title: "Religious Goods Stores", value: "5973" },
        { title: "Religious Organizations", value: "8661" },
        { title: "Roofing/Siding, Sheet Metal", value: "1761" },
        { title: "Secretarial Support Services", value: "7339" },
        { title: "Security Brokers/Dealers", value: "6211" },
        { title: "Service Stations", value: "5541" },
        { title: "Sewing, Needlework, Fabric, and Piece Goods Stores", value: "5949" },
        { title: "Shoe Repair/Hat Cleaning", value: "7251" },
        { title: "Shoe Stores", value: "5661" },
        { title: "Small Appliance Repair", value: "7629" },
        { title: "Snowmobile Dealers", value: "5598" },
        { title: "Special Trade Services", value: "1799" },
        { title: "Specialty Cleaning", value: "2842" },
        { title: "Sporting Goods Stores", value: "5941" },
        { title: "Sporting/Recreation Camps", value: "7032" },
        { title: "Sports Clubs/Fields", value: "7941" },
        { title: "Sports and Riding Apparel Stores", value: "5655" },
        { title: "Stamp and Coin Stores", value: "5972" },
        { title: "Stationary, Office Supplies, Printing and Writing Paper", value: "5111" },
        { title: "Stationery Stores, Office, and School Supply Stores", value: "5943" },
        { title: "Swimming Pools Sales", value: "5996" },
        { title: "TUI Travel - Germany", value: "4723" },
        { title: "Tailors, Alterations", value: "5697" },
        { title: "Tax Payments - Government Agencies", value: "9311" },
        { title: "Tax Preparation Services", value: "7276" },
        { title: "Taxicabs/Limousines", value: "4121" },
        { title: "Telecommunication Equipment and Telephone Sales", value: "4812" },
        { title: "Telecommunication Services", value: "4814" },
        { title: "Telegraph Services", value: "4821" },
        { title: "Tent and Awning Shops", value: "5998" },
        { title: "Testing Laboratories", value: "8734" },
        { title: "Theatrical Ticket Agencies", value: "7922" },
        { title: "Timeshares", value: "7012" },
        { title: "Tire Retreading and Repair", value: "7534" },
        { title: "Tolls/Bridge Fees", value: "4784" },
        { title: "Tourist Attractions and Exhibits", value: "7991" },
        { title: "Towing Services", value: "7549" },
        { title: "Trailer Parks, Campgrounds", value: "7033" },
        { title: "Transportation Services (Not Elsewhere Classified)", value: "4789" },
        { title: "Travel Agencies, Tour Operators", value: "4722" },
        { title: "Truck StopIteration", value: "7511" },
        { title: "Truck/Utility Trailer Rentals", value: "7513" },
        { title: "Typesetting, Plate Making, and Related Services", value: "2791" },
        { title: "Typewriter Stores", value: "5978" },
        { title: "U.S. Federal Government Agencies or Departments", value: "9405" },
        { title: "Uniforms, Commercial Clothing", value: "5137" },
        { title: "Used Merchandise and Secondhand Stores", value: "5931" },
        { title: "Utilities", value: "4900" },
        { title: "Variety Stores", value: "5331" },
        { title: "Veterinary Services", value: "0742" },
        { title: "Video Amusement Game Supplies", value: "7993" },
        { title: "Video Game Arcades", value: "7994" },
        { title: "Video Tape Rental Stores", value: "7841" },
        { title: "Vocational/Trade Schools", value: "8249" },
        { title: "Watch/Jewelry Repair", value: "7631" },
        { title: "Welding Repair", value: "7692" },
        { title: "Wholesale Clubs", value: "5300" },
        { title: "Wig and Toupee Stores", value: "5698" },
        { title: "Wires, Money Orders", value: "4829" },
        { title: "Womens Accessory and Specialty Shops", value: "5631" },
        { title: "Womens Ready-To-Wear Stores", value: "5621" },
        { title: "Wrecking and Salvage Yards", value: "5935" }
      ]
    };
  }
};
